// DEPENDS ON SIZES and COLORS

/*style*/
.brdr-solid {
  .brdr(@style: solid);
}

.brdr-dashed {
  .brdr(@style: dashed);
}

.brdr-dotted {
  .brdr(@style: dotted);
}

.brdr-bottom-dotted {
  border-bottom: 2px dotted;
  text-decoration: none;
}

.brdr-none-transparent {
  border: none;
  background-color: transparent;
}

.brdr-none {
  border: none;
}

.brdr-end-2 {
  border-right: solid @2;
}

.brdr-bottom-2 {
  border-bottom: solid 2px;
}

.brdr-top-dark {
  border-top: 1px solid @charcoal;
}

.brdr-left-dark {
  border-left: 1px solid @charcoal;
}

.brdr-bottom-dark {
  border-bottom: 1px solid @charcoal;
}


.brdr-bottom-dark-fuzzy {
  border-bottom: 1px;
  box-shadow: 0px 0px 5px @pale-grey-50;
}

.brdr-bottom-mint {
  border-bottom: 5px solid @dark-mint
}

.brdr-left-light {
  border-left: 1px solid @light-grey;
}

.brdr-vibrant-blue {
  border: 1px solid @vibrant-blue;
}

.brdr-dark-blue {
  border: 1px solid @dark-blue;
}

.brdr-dark-sea-blue-thin {
  border: 1px solid @dark-sea-blue
}

.brdr-orange {
  border: 2px solid @cherry-red
}

.brdr-left-orange {
  border-left: 52px solid @cherry-red
}

.brdr-left-vibrant-blue {
  border-left: 2px solid @vibrant-blue;
}

.brdr-right-vibrant-blue {
  border-right: 2px solid @vibrant-blue;
}

.brdr-bottom-vibrant-blue-lg {
  border-bottom: 20px solid @vibrant-blue;
}


.brdr-bottom-light {
  border-bottom: 1px solid @light-grey;
}

.brdr-bottom-pale {
  border-bottom: 1px solid @pale-grey
}

.brdr-bottom-grey {
  border-bottom: 2px solid @light-grey;
}

.brdr-top-dark-grey {
  border-top: 1px solid @dark-grey;
}


.brdr-right-dark-grey {
  border-right: 1px solid @dark-grey;
}

.brdr-bottom-pale-grey {
  border-bottom: 1px solid @pale-grey;
}

.brdr-right-pale-grey {
  border-right: 1px solid @pale-grey;
}

.brdr-threeQuarters-dark-grey {
  border-top: 1px solid @dark-grey;
  border-left: 1px solid @dark-grey;
  border-bottom: 1px solid @dark-grey
}

.brdr-bottom-dark-grey {
  border-bottom: 1px solid @dark-grey;
}

.brdr-bottom-dark-grey-2 {
  border-bottom: 2px solid @dark-grey;
}

.brdr-bottom-dark-grey-4 {
  border-bottom: 4px solid @dark-grey;
}

.brdr-bottom-dark-grey-8 {
  border-bottom: 8px solid @dark-grey;
}

.brdr-top-dark-grey-8 {
  border-top: 8px solid @dark-grey;
}


.brdr-x-dark-grey {
  border-left: 1px solid @dark-grey;
  border-right: 1px solid @dark-grey;
}

.brdr-y-dark-grey {
  border-top: 1px solid @dark-grey;
  border-bottom: 1px solid @dark-grey
}

.brdr-y-vibrant-blue {
  border-bottom: 5px solid @vibrant-blue;
  border-top: 5px solid @vibrant-blue;
}

/*width*/
.brdr-0 {
  .brdr(0px);
}

.brdr-1 {
  .brdr(1px);
}

.brdr-2 {
  .brdr(2px);
}

.brdr-3 {
  .brdr(3px);
}

.brdr-4 {
  .brdr(4px);
}

.brdr-5 {
  .brdr(5px);
}

/*color*/

.brdr-black {
  .brdr(@color: black);
}

.brdr-dark-grey {
  .brdr(@color: @dark-grey);
}

.brdr-pale-grey {
  .brdr(@color: @pale-grey);
}

.brdr-white {
  .brdr(@color: white);
}

.brdr-primary-500 {
  .brdr(@color: @blue);
}

.brdr-error {
  .brdr(@color: red);
}

.brdr-dark-red {
  .brdr(@color: @dark-red);
}

.brdr-success {
  .brdr(@color: green);
}

.brdr (@width: @border-width-base, @style: @border-style-base, @color: @border-color-base) {
  border: @width @style @color;
}

.rounded-border (@radius: @border-radius-base, @width, @style, @color) {
  border-radius: @radius;
  .brdr(@width, @style, @color);
}

.rounded-border-none {
  border-radius: 0px;
}

.rounded-border-2 {
  border-radius: 2px;
}

.rounded-border-3 {
  border-radius: 3px;
}

.rounded-border-4 {
  border-radius: 4px;
}

.rounded-border-6 {
  border-radius: 6px;
}

.rounded-border-8 {
  border-radius: 8px;
}

.rounded-border-10 {
  border-radius: 10px;
}

.rounded-border-50 {
  border-radius: 100px;
}

.rounded-border-bottom-4 {
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
}

.rounded-border-top-4 {
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
}

.shadow-25 {
  box-shadow: 0px 1px 5px @faded-charcoal-25;
}

.shadow {
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}