@import "../styles/fonts/font-colors.less";
@import "./sizes.less";
@import "./variables.less";

.landing-heading {
    color: @nw-medium-blue;
    font-size: @7;
}

// Position
// -----------------------------
// DEPENDS ON MEDIA-QUERIES , VARIABLES and COLORS
.absolute-center {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.absolute-top-60 {
    position: absolute;
    top: 60%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.absolute-top-70 {
    position: absolute;
    top: 70%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.position-absolute {
    position: absolute;
}

.position-fixed {
    position: fixed;
}

.position-initial {
    position: initial;
}

.position-fixed-bottom {
    bottom: 0px;
    left: 0;
    position: fixed;
    width: 100%;
}

.text-bottom {
    position: absolute;
    bottom: 10px;
    width: 50%;
}

.position-fixed-top {
    top: 86px;
    left: 0;
    position: fixed;
    width: 100%;
    z-index: 100;
}

.z-index-top {
    z-index: 100;
}

.position-relative {
    position: relative;
}

.clear {
    clear: both;
}

.d-block {
    display: block;
}

.d-inline-block {
    display: inline-block;
}

.d-flex {
    display: flex;
}

.d-inline-flex {
    display: inline-flex;
}

.d-none {
    display: none;
}

.d-block-md-up {
    @media @md-up {
        .d-block;
    }
}

.d-inline-block-md-up {
    @media @md-up {
        .d-inline-block;
    }
}

.d-none-md-up {
    @media @md-up {
        .d-none;
    }
}

.d-block-center {
    display: block;
    margin-left: auto;
    margin-right: auto;
}

.no-visibility {
    visibility: hidden;
}

.visible {
    visibility: visible;
}

.flex-column {
    display: flex;
    flex-direction: column;
}

.justify-space-between {
    justify-content: space-between;
}

.justify-center {
    justify-content: center;
}

.justify-right {
    justify-content: right;
}

.justify-space-evenly {
    justify-content: space-evenly;
}

// Overflow & scroll
// -----------------------------
.overflow-hidden {
    overflow: hidden;
}

.overflow-visible {
    overflow: visible;
}

.overflow-auto,
.scroll {
    overflow: auto;
}

.overflow-x-auto,
.scroll-horizontal {
    overflow-x: auto;
}

.overflow-x-preserve,
.scroll-horizontal-preserve {
    white-space: pre;
}

.text-responsive {
    .font-size-normal;

    @media @sm-down {
        .font-size-sm;
    }

    @media @xs-down {
        .font-size-xs;
    }
}

.text-responsive-sm {
    .font-size-sm;

    @media @sm-down {
        .font-size-xs;
    }

    @media @xs-down {
        .font-size-xxs;
    }
}

.text-responsive-lg {
    .font-size-lg;

    @media @sm-down {
        .font-size-normal;
    }

    @media @xs-down {
        .font-size-sm;
    }
}

.text-responsive-xxxl {
    .font-size-xxxl;

    @media @sm-down {
        .font-size-xl;
    }

    @media @xs-down {
        .font-size-lg;
    }
}


.overflow-y-auto,
.scroll-vertical {
    overflow-y: auto;
}

// Line Height
// -----------------------------
.line-height-tightest {
    line-height: @line-height-tightest;
}

.line-height-tighter {
    line-height: @line-height-tighter;
}

.line-height-tight {
    line-height: @line-height-tight;
}

.line-height-base {
    line-height: @line-height-base;
}

.line-height-loose {
    line-height: @line-height-loose;
}

.line-height-looser {
    line-height: @line-height-looser;
}

.line-height-md {
    line-height: @line-height-md;
}

.line-height-loosest {
    line-height: @line-height-loosest;
}

.line-height-tightest-md-up {
    @media @md-up {
        .line-height-tightest;
    }
}

.line-height-tightest-lg-up {
    @media @lg-up {
        .line-height-tightest;
    }
}

.line-height-base-lg-up {
    @media @lg-up {
        .line-height-base;
    }
}

// Typography
// -----------------------------
.text-uppercase {
    text-transform: uppercase;
}

.italic {
    font-style: italic;
}

// font size
.font-size-xxxs {
    font-size: 10px;
}

.font-size-xxs {
    font-size: 12px;
}

.font-size-xs {
    font-size: 14px;
}

.font-size-sm {
    font-size: 16px;
}

.font-size-normal {
    font-size: 18px;
}

.font-size-md {
    font-size: 20px;
}

.font-size-lg {
    font-size: @font-size-lg;
}

.font-size-xl {
    font-size: @font-size-xl;
}

.font-size-xxl {
    font-size: @font-size-xxl;
}

.font-size-xxxl {
    font-size: @font-size-xxxl;
}

.font-size-xxxxl {
    font-size: @font-size-xxxxl;
}

.font-size-xxxxxl {
    font-size: @font-size-xxxxxl;
}

.font-size-xxxxxxl {
    font-size: 80px;
}


.font-size-huge {
    font-size: @font-size-huge;
}

.font-size-xl-lg-up {
    @media @lg-up {
        .font-size-xl;
    }
}

.font-page-icon {
    font-size: 48px;
    color: @vibrant-blue;
}

.font-page-icon-white {
    font-size: 48px;
    color: @white;
}


.font-family-tiemposFine {
    font-family: "TiemposFine";
}

.font-family-TiemposHeadline {
    font-family: "TiemposHeadline";
}

.font-family-gotham {
    font-family: "Gotham";
}

// font weights
///////////////

.text-normal {
    font-weight: normal;
}

.text-xlight {
    font-weight: @font-weight-xlight;
}

.text-light {
    font-weight: @font-weight-light;
}

.text-regular {
    font-weight: @font-weight-regular;
}

.text-medium {
    font-weight: @font-weight-medium;
}

.text-semibold {
    font-weight: @font-weight-semibold;
}

.text-bold {
    font-weight: @font-weight-medium;
}

.text-heavy {
    font-weight: @font-weight-bold;
}

.text-center {
    text-align: center;
}

.text-middle {
    vertical-align: middle;
}

.text-inherit {
    vertical-align: inherit;
}

.text-left {
    text-align: left;
}

.text-right {
    text-align: right;
}

.text-left-md-up {
    @media @md-up {
        .text-left;
    }
}

.text-header-normal {
    .font-size-md;
    .text-vibrant-blue;
}

.text-subheader-normal {
    .text-medium;
}

strong {
    font-weight: @font-weight-medium;
}

// Text colors
//////////////

.text-white {
    color: @white;
}

.text-white-small {
    color: @white;
    font-size: 20px;
}

.text-big {
    color: @vibrant-blue;
    font-weight: @font-weight-heavy;
    font-family: "TiemposFine";

    @media @sm-down {
        font-size: 1.5em;
    }
}

.text-bold-blue {
    color: @vibrant-blue;
    font-weight: @font-weight-semibold;
}

.text-bold-blue-fancy {
    color: @vibrant-blue;
    font-family: TiemposHeadline;
    font-size: @font-size-xxxl;
}

.text-bold-blue-padded {
    color: @vibrant-blue;
    font-family: TiemposHeadline;
    padding-top: 48px;
    font-size: @font-size-xxxl;
}

.text-center-blueBanner {
    text-align: center;
    background-color: @vibrant-blue;
    height: 250px;
}

.text-vibrant-blue {
    color: @vibrant-blue;
}


.text-dark-blue {
    color: @dark-blue;
}

.text-indigo-wild {
    color: @indigo-wild
}

.text-light-blue {
    color: @light-blue;
}

.text-deep-purple {
    color: @deep-purple;
}

.text-medium-purple {
    color: @medium-purple;
}

.body-text {
    font-size: 14px;
}

.text-dark-mint {
    color: @dark-mint;
}

.text-mint {
    color: @mint;
}

.text-lime {
    color: @lime;
}

.text-cucumber-palm {
    color: @cucumber-palm;
}

.text-success {
    color: @success;
}

.text-black {
    color: @black;
}

.text-black-important {
    color: @black !important;
}

.text-dark-gray {
    color: @charcoal;
}

.text-gray {
    color: @whisper;
}

.text-dark-sea-blue {
    color: @dark-sea-blue;
}

.text-sea-blue {
    color: @sea-blue;
}

.text-pale-blue {
    color: @pale-blue;
}

.text-perano-blue {
    color: @perano-blue;
}

.text-dark-grey {
    color: @dark-grey;
}

.text-dusky-grey {
    color: @dusky-grey;
}

.text-red {
    color: @red;
}

.text-dark-red {
    color: @dark-red;
}

.text-disclaimer {
    color: @disclaimer;
}

.text-primary {
    color: @vibrant-blue;
}

.text-error {
    color: @error-color;
}

.text-sunburst-orange {
    color: @sunburst-orange;
}

.text-mute {
    color: @pale-grey;
}

.whitespace-preline {
    white-space: pre-line;
}

.text-truncate {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.nowrap {
    white-space: nowrap;
}

.break-spaces {
    white-space: break-spaces;
}

.pre-wrap {
    white-space: pre-wrap;
}

.normal-white-space {
    white-space: normal;
}

.warning-text {
    color: @red;
    font-size: 14px;
    position: relative;
    top: 3px;
    left: 5px;
}

// Width
// -----------------------------
.w-full {
    width: 100% !important;
}

.w-auto {
    width: auto;
}

.w-big {
    width: 80%
}

.w-threeQuarter {
    width: 75%;
}

.w-half {
    width: 50%;
}

.w-third {
    width: 43%;
}

.w-quarter {
    width: 25%;
}

.w-smallerQuarter {
    width: 22%;
}

// TODO: use nzGrid
@media @sm-down {
    .w-1\/4 {
        width: 100%;
    }

    .w-3\/4 {
        width: 100%;
    }

    .w-1\/2 {
        width: 100%;
    }
}

@media @md-up {
    .w-1\/4 {
        width: 25%;
    }

    .w-1\/2 {
        width: 50%;
    }

    .w-3\/4 {
        width: 75%;
    }
}

.max-w-none {
    max-width: none;
}

.max-w-sm {
    max-width: @container-max-width-sm;
}

.max-w-lg {
    max-width: @container-max-width-lg;
}

.max-w-button {
    max-width: 335px;
}

.max-w-xl {
    max-width: @container-max-width-xl;
}

// Height
// -----------------------------
.h-full {
    height: 100%;
}

.h-90 {
    height: 90%
}

.h-auto {
    height: auto;
}

.h-threeQuarter {
    height: 75%;
}

.h-half {
    height: 50%;
}

.h-quarter {
    height: 25%;
}

// Floats
// -----------------------------
.float-right {
    float: right;
}

.float-left {
    float: left;
}

// Text Decoration
// -----------------------------
.text-decoration-none {
    text-decoration: none;
}

.text-decoration-underline {
    text-decoration: underline;
}

.underline {
    text-decoration: underline;
}


.text-decoration-dotted {
    text-decoration: dotted;
}

.text-decoration-dashed {
    text-decoration: dashed;
}


// Icon Sizing
// -----------------------------
.icon-base {
    font-size: @icon-base;
}


// Anchors
// -----------------------------
a:hover {
    text-decoration: underline;
}