/* You can add global styles to this file, and also import other style files */
@import "@nationwide-bolt/adapter-material/dist/bolt-material-theme";
@import '@nationwide-bolt/css-core/dist/css/bolt-core';

@import './styles/fonts/font-colors.less';
@import './styles/media-queries/media-queries.less';
@import './styles/mixins/globals.less';
@import './styles/bolt-additions.less';
@import './styles/borders.less';
@import './styles/separators.less';
@import './styles/sizes.less';
@import './styles/spacing.less';
@import './styles/text.less';
@import './styles/variables.less';
@import './styles/wireframe.less';

body {
  font-family: Gotham;
  overflow: auto;
}

/* global styling for sticky footer*/
html,
body,
app-root {
  height: 100%;
  overflow: auto;
}

body {
  margin-bottom: 0;
  margin-top: 0;
}

.bolt-typography ul,
.bolt-typography ol {
  margin: 0 0 0 0;
}
.bolt-typography .bolt-footer-container {
  color: #fff;
  display: block;
  background: var(--bolt-foreground-color, #171717);
  padding: 52px 84px;
  font-size: 14px;
}

.bolt-typography .bolt-footer-social {
  line-height: 14px;
}

html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }

//styles for paginator
.button-node-container {
  display: flex;
  gap: 4px;
}

.button-node {
  background: transparent;
  border: none;
  width: 34px;
  height: 34px;
  display: flex;
  align-items: center;
  justify-content: center;
  color:  #0047BB;
  font-size: 1rem;
  cursor: pointer;
  font-weight: 400;
}

.button-node__active {
  background-color: #F4F4F5;
  color: #000;
}

mat-paginator {
  background: transparent !important;
}

.mat-mdc-paginator-range-actions {
  margin-right: 12px !important;
}