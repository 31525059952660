@import "./media-queries/containers.less";

.level-0 {
    z-index: 0;
}

.level-1 {
    z-index: 100;
}

.level-2 {
    z-index: 200;
}

.level-3 {
    z-index: 300;
}

.level-4 {
    z-index: 400;
}

.level-5 {
    z-index: 500;
}

.level-6 {
    z-index: 600;
}

.level-7 {
    z-index: 700;
}

.level-8 {
    z-index: 800;
}

.level-9 {
    z-index: 900;
}

.level-10 {
    z-index: 1000;
}



.ant-drawer-content {
    top: 64px;
}


.h-100-pc {
    height: 100%;
}

.h-80-pc {
    height: 80%;
}

.h-30-px {
    height: 30px;
}

.h-40-px {
    height: @10;
}

.h-80-px {
    height: 80px;
}

.h-100-px {
    height: 100px;
}

.h-125-px {
    height: 125px;
}

.w-30-pc {
    width: 30%;
}

.w-33-pc {
    width: 33%;
}

.w-34-pc {
    width: 34%;
}

.w-40-pc {
    width: 40%;
}

.w-40-px {
    width: @10;
}

.w-50-pc {
    width: 50%;
}

.w-60-pc {
    width: 60%;
}

.w-80-pc {
    width: 80%;
}

.w-80-px {
    width: 80px;
}

.w-120-px {
    width: 120px;
}

.w-200-px {
    width: 200px;
}

.w-220-px {
    width: 220px;
}

.w-90-pc {
    width: 90%
}

.w-100-pc {
    width: 100%
}


// Margin support classes
// -----------------------------
.m-auto {
    margin: auto;
}

.m-0 {
    margin: 0;
}

.m-1 {
    margin: @1;
}

.m-2 {
    margin: @2;
}

.m-3 {
    margin: @3;
}

.m-4 {
    margin: @4;
}

.m-5 {
    margin: @5;
}

.m-6 {
    margin: @6;
}

.m-7 {
    margin: @7;
}

.m-8 {
    margin: @8;
}

.m-xs {
    margin: @1;
}

.m-sm {
    margin: @2;
}

.m-md {
    margin: @3;
}

.m-lg {
    margin: @5;
}

.m-xl {
    margin: @8;
}

.m-xxl {
    margin: @13;
}

.m-xxxl {
    margin: @1*21;
}

.m-xxxxl {
    margin: @1*34;
}

.mx-auto {
    margin-right: auto;
    margin-left: auto;
}

.mx-0 {
    margin-right: 0;
    margin-left: 0;
}

.mx-1 {
    margin-right: @1;
    margin-left: @1;
}

.mx-2 {
    margin-right: @2;
    margin-left: @2;
}

.mx-3 {
    margin-right: @3;
    margin-left: @3;
}

.mx-4 {
    margin-right: @4;
    margin-left: @4;
}

.mx-5 {
    margin-right: @5;
    margin-left: @5;
}

.mx-6 {
    margin-right: @6;
    margin-left: @6;
}

.mx-7 {
    margin-right: @7;
    margin-left: @7;
}

.mx-8 {
    margin-right: @8;
    margin-left: @8;
}

.mx-xs {
    margin-right: @1;
    margin-left: @1;
}

.mx-sm {
    margin-right: @2;
    margin-left: @2;
}

.mx-md {
    margin-right: @3;
    margin-left: @3;
}

.mx-lg {
    margin-right: @5;
    margin-left: @5;
}

.mx-xl {
    margin-right: @8;
    margin-left: @8;
}

.mx-xxl {
    margin-right: @13;
    margin-left: @13;
}

.mx-xxxl {
    margin-right: @1*21;
    margin-left: @1*21;
}

.mx-xxxxl {
    margin-right: @1*34;
    margin-left: @1*34;
}

.my-auto {
    margin-top: auto;
    margin-bottom: auto;
}

.my-0 {
    margin-top: 0;
    margin-bottom: 0;
}

.my-1 {
    margin-top: @1;
    margin-bottom: @1;
}

.my-2 {
    margin-top: @2;
    margin-bottom: @2;
}

.my-3 {
    margin-top: @3;
    margin-bottom: @3;
}

.my-4 {
    margin-top: @4;
    margin-bottom: @4;
}

.my-5 {
    margin-top: @5;
    margin-bottom: @5;
}

.my-6 {
    margin-top: @6;
    margin-bottom: @6;
}

.my-7 {
    margin-top: @7;
    margin-bottom: @7;
}

.my-8 {
    margin-top: @8;
    margin-bottom: @8;
}

.my-9 {
    margin-top: @9;
    margin-bottom: @9;
}

.my-10 {
    margin-top: @10;
    margin-bottom: @10;
}

.my-11 {
    margin-top: @11;
    margin-bottom: @11;
}

.my-12 {
    margin-top: @12;
    margin-bottom: @12;
}

.my-13 {
    margin-top: @13;
    margin-bottom: @13;
}

.my-xs {
    margin-top: @1;
    margin-bottom: @1;
}

.my-sm {
    margin-top: @2;
    margin-bottom: @2;
}

.my-md {
    margin-top: @3;
    margin-bottom: @3;
}

.my-lg {
    margin-top: @5;
    margin-bottom: @5;
}

.my-xl {
    margin-top: @8;
    margin-bottom: @8;
}

.my-xxl {
    margin-top: @13;
    margin-bottom: @13;
}

.my-xxxl {
    margin-top: @1*21;
    margin-bottom: @1*21;
}

.my-xxxxl {
    margin-top: @1*34;
    margin-bottom: @1*34;
}

.mt--30 {
    margin-top: -30px;
}

.mt--20 {
    margin-top: -@5;
}

.mt--12 {
    margin-top: -@3
}

.mt-0 {
    margin-top: 0;
}

.mt-small {
    margin-top: 3px;
}

.mt-1 {
    margin-top: @1;
}

.mt-2 {
    margin-top: @2;
}

.mt-3 {
    margin-top: @3;
}

.mt-4 {
    margin-top: @4;
}

.mt-5 {
    margin-top: @5;
}

.mt-6 {
    margin-top: @6;
}

.mt-7 {
    margin-top: @7;
}

.mt-8 {
    margin-top: @8;
}

.mt-9 {
    margin-top: @9;
}

.mt-10 {
    margin-top: @10;
}

.mt-11 {
    margin-top: @11;
}

.mt-12 {
    margin-top: @12;
}

.mt-100 {
    margin-top: 100px;
}

.mt-xs {
    margin-top: @1;
}

.mt-sm {
    margin-top: @2;
}

.mt-md {
    margin-top: @3;
}

.mt-lg {
    margin-top: @5;
}

.mt-xl {
    margin-top: @8;
}

.mt-xxl {
    margin-top: @13;
}

.mt-xxxl {
    margin-top: @1*21;
}

.mt-xxxxl {
    margin-top: @1*34;
}

.mr-auto {
    margin-right: auto;
}

.mr-0 {
    margin-right: 0;
}

.mr-1 {
    margin-right: @1;
}

.mr-2 {
    margin-right: @2;
}

.mr-3 {
    margin-right: @3;
}

.mr-4 {
    margin-right: @4;
}

.mr-5 {
    margin-right: @5;
}

.mr-6 {
    margin-right: @6;
}

.mr-7 {
    margin-right: @7;
}

.mr-8 {
    margin-right: @8;
}

.mr-9 {
    margin-right: @9;
}

.mr-10 {
    margin-right: @10;
}

.mr-11 {
    margin-right: @11;
}

.mr-12 {
    margin-right: @12;
}

.mr-xs {
    margin-right: @1;
}

.mr-sm {
    margin-right: @2;
}

.mr-md {
    margin-right: @3;
}

.mr-lg {
    margin-right: @5;
}

.mr-xl {
    margin-right: @8;
}

.mr-xxl {
    margin-right: @13;
}

.mr-xxxl {
    margin-right: @1*21;
}

.mr-xxxxl {
    margin-right: @1*34;
}

.mb-0 {
    margin-bottom: 0;
}

.mb-1 {
    margin-bottom: @1;
}

.mb-2 {
    margin-bottom: @2;
}

.mb-3 {
    margin-bottom: @3;
}

.mb-4 {
    margin-bottom: @4;
}

.mb-5 {
    margin-bottom: @5;
}

.mb-6 {
    margin-bottom: @6;
}

.mb-7 {
    margin-bottom: @7;
}

.mb-8 {
    margin-bottom: @8;
}

.mb-9 {
    margin-bottom: @9;
}

.mb-10 {
    margin-bottom: @10;
}

.mb-11 {
    margin-bottom: @11;
}

.mb-12 {
    margin-bottom: @12;
}

.mb-xs {
    margin-bottom: @1;
}

.mb-sm {
    margin-bottom: @2;
}

.mb-md {
    margin-bottom: @3;
}

.mb-lg {
    margin-bottom: @5;
}

.mb-xl {
    margin-bottom: @8;
}

.mb-xxl {
    margin-bottom: @13;
}

.mb-xxxl {
    margin-bottom: @1*21;
}

.mb-xxxxl {
    margin-bottom: @1*34;
}

.ml-13-mb-10 {
    margin-left: @13;
    margin-bottom: @10;
}

.mb-sticky-footer {
    margin-bottom: 110px;
}

.mt-fixed-tabs {
    margin-top: 216px;
}

.ml-auto {
    margin-right: auto;
}

.ml-0 {
    margin-left: 0;
}

.ml-1 {
    margin-left: @1;
}

.ml-2 {
    margin-left: @2;
}

.ml-3 {
    margin-left: @3;
}

.ml-4 {
    margin-left: @4;
}

.ml-5 {
    margin-left: @5;
}

.ml-6 {
    margin-left: @6;
}

.ml-7 {
    margin-left: @7;
}

.ml-8 {
    margin-left: @8;
}

.ml-9 {
    margin-left: @9;
}

.ml-10 {
    margin-left: @10;
}

.ml-11 {
    margin-left: @11;
}

.ml-12 {
    margin-left: @12;
}

.ml-13 {
    margin-left: @13;
}

.ml-xs {
    margin-left: @1;
}

.ml-sm {
    margin-left: @2;
}

.ml-md {
    margin-left: @3;
}

.ml-lg {
    margin-left: @5;
}

.ml-xl {
    margin-left: @8;
}

.ml-xxl {
    margin-left: @13;
}

.ml-xxxl {
    margin-left: @1*21;
}

.ml-xxxxl {
    margin-left: @1*34;
}

.mx-content-negative {
    margin-left: -@4;
    margin-right: -@4;

    @media @sm-up {
        margin-left: -@8;
        margin-right: -@8;
    }

    @media @md-up {
        margin-left: -64px;
        margin-right: -64px;
    }
}


// Padding support classes
// -----------------------------
.p-0 {
    padding: @0;
}

.p-1 {
    padding: @1;
}

.p-1-5 {
    padding: @1-5;
}


.p-2 {
    padding: @2;
}

.p-3 {
    padding: @3;
}

.p-4 {
    padding: @4;
}

.p-5 {
    padding: @5;
}

.p-6 {
    padding: @6;
}

.p-7 {
    padding: @7;
}

.p-8 {
    padding: @8;
}

.p-9 {
    padding: @9;
}

.p-10 {
    padding: @10;
}

.p-11 {
    padding: @11;
}

.p-12 {
    padding: @12;
}

@padding-xl: @9;
@padding-xxl: @12;

// .p-xl { padding: @padding-xl; }
// .p-xxl { .p-12 }
.p-2-l-11 {
    padding: @2;
    padding-left: @12;
}

.p-xs {
    padding: @1;
}

.p-sm {
    padding: @2;
}

.p-md {
    padding: @3;
}

.p-lg {
    padding: @5;
}

.p-xl {
    padding: @8;
}

.p-xxl {
    padding: @13;
}

.p-xxxl {
    padding: @1*21;
}

.p-xxxxl {
    padding: @1*34;
}

.px-0 {
    padding-right: 0;
    padding-left: 0;
}

.px-1 {
    padding-right: @1;
    padding-left: @1;
}

.px-1-5 {
    padding-right: @1-5;
    padding-left: @1-5;
}

.px-2 {
    padding-right: @2;
    padding-left: @2;
}

.px-3 {
    padding-right: @3;
    padding-left: @3;
}

.px-4 {
    padding-right: @4;
    padding-left: @4;
}

.px-5 {
    padding-right: @5;
    padding-left: @5;
}

.px-6 {
    padding-right: @6;
    padding-left: @6;
}

.px-7 {
    padding-right: @7;
    padding-left: @7;
}

.px-8 {
    padding-right: @8;
    padding-left: @8;
}

.px-9 {
    padding-right: @9;
    padding-left: @9;
}

.px-10 {
    padding-right: @10;
    padding-left: @10;
}

.px-12 {
    padding-right: @12;
    padding-left: @12;
}

.px-13 {
    padding-right: @13;
    padding-left: @13;
}

.px-14 {
    padding-right: @14;
    padding-left: @14;
}

// .px-xl { padding-right: @padding-xl; padding-left: @padding-xl; }
// .px-xxl { padding-right: @padding-xxl; padding-left: @padding-xxl; }

.px-xs {
    padding-right: @1;
    padding-left: @1;
}

.px-sm {
    padding-right: @2;
    padding-left: @2;
}

.px-md {
    padding-right: @3;
    padding-left: @3;
}

.px-lg {
    padding-right: @5;
    padding-left: @5;
}

.px-xl {
    padding-right: @8;
    padding-left: @8;
}

.px-xxl {
    padding-right: @13;
    padding-left: @13;
}

.px-xxxl {
    padding-right: @1*21;
    padding-left: @1*21;
}

.px-xxxxl {
    padding-right: @1*34;
    padding-left: @1*34;
}

.py-0 {
    padding-top: 0;
    padding-bottom: 0;
}

.py-1 {
    padding-top: @1;
    padding-bottom: @1;
}

.py-1-5 {
    padding-top: @1-5;
    padding-bottom: @1-5;
}

.py-2 {
    padding-top: @2;
    padding-bottom: @2;
}

.py-3 {
    padding-top: @3;
    padding-bottom: @3;
}

.py-4 {
    padding-top: @4;
    padding-bottom: @4;
}

.py-5 {
    padding-top: @5;
    padding-bottom: @5;
}

.py-4-7-4 {
    padding-top: 15px;
    padding-bottom: 30px;
    margin-bottom: @4
}

.py-6 {
    padding-top: @6;
    padding-bottom: @6;
}

.py-7 {
    padding-top: @7;
    padding-bottom: @7;
}

.py-8 {
    padding-top: @8;
    padding-bottom: @8;
}

.py-9 {
    padding-top: @9;
    padding-bottom: @9;
}

.py-10 {
    padding-top: @10;
    padding-bottom: @10;
}

.py-11 {
    padding-top: @11;
    padding-bottom: @11;
}

.py-12 {
    padding-top: @12;
    padding-bottom: @12;
}

.py-13 {
    padding-top: @13;
    padding-bottom: @13;
}

.py-14 {
    padding-top: @14;
    padding-bottom: @14;
}


// .py-xl { padding-top: @padding-xl; padding-bottom: @padding-xl; }
// .py-xxl { padding-top: @padding-xxl; padding-bottom: @padding-xxl; }
.py-xs {
    padding-top: @1;
    padding-bottom: @1;
}

.py-sm {
    padding-top: @2;
    padding-bottom: @2;
}

.py-md {
    padding-top: @3;
    padding-bottom: @3;
}

.py-lg {
    padding-top: @5;
    padding-bottom: @5;
}

.py-xl {
    padding-top: @8;
    padding-bottom: @8;
}

.py-xxl {
    padding-top: @13;
    padding-bottom: @13;
}

.py-xxxl {
    padding-top: @1*21;
    padding-bottom: @1*21;
}

.py-xxxxl {
    padding-top: @1*34;
    padding-bottom: @1*34;
}

.pt-0 {
    padding-top: 0;
}

.pt-1 {
    padding-top: @1;
}

.pt-1-5 {
    padding-top: @1-5;
}

.pt-2 {
    padding-top: @2;
}

.pt-3 {
    padding-top: @3;
}

.pt-4 {
    padding-top: @4;
}

.pt-5 {
    padding-top: @5;
}

.pt-6 {
    padding-top: @6;
}

.pt-7 {
    padding-top: @7;
}

.pt-8 {
    padding-top: @8;
}

.pt-9 {
    padding-top: @9;
}

.pt-10 {
    padding-top: @10;
}

.pt-11 {
    padding-top: @11;
}

.pt-12 {
    padding-top: @12;
}

.pt-13 {
    padding-top: @13;
}

.pt-14 {
    padding-top: @14;
}


// .pt-xl { padding-top: @padding-xl; }
// .pt-xxl { padding-top: @padding-xxl; }

.pt-xs {
    padding-top: @1;
}

.pt-sm {
    padding-top: @2;
}

.pt-md {
    padding-top: @3;
}

.pt-lg {
    padding-top: @5;
}

.pt-xl {
    padding-top: @8;
}

.pt-xxl {
    padding-top: @13;
}

.pt-xxxl {
    padding-top: @1*21;
}

.pt-xxxxl {
    padding-top: @1*34;
}

.pr-0 {
    padding-right: 0;
}

.pr-half {
    padding-right: @half
}

.pr-1 {
    padding-right: @1;
}

.pr-1-5 {
    padding-right: @1-5;
}

.pr-2 {
    padding-right: @2;
}

.pr-3 {
    padding-right: @3;
}

.pr-4 {
    padding-right: @4;
}

.pr-5 {
    padding-right: @5;
}

.pr-6 {
    padding-right: @6;
}

.pr-7 {
    padding-right: @7;
}

.pr-8 {
    padding-right: @8;
}

.pr-9 {
    padding-right: @9;
}

.pr-10 {
    padding-right: @10;
}

.pr-11 {
    padding-right: @11
}

.pr-75 {
    padding-right: 300px;
}

// .pr-xl { padding-right: @padding-xl; }
// .pr-xxl { padding-right: @padding-xxl; }
.pr-xs {
    padding-right: @1;
}

.pr-sm {
    padding-right: @2;
}

.pr-md {
    padding-right: @3;
}

.pr-lg {
    padding-right: @5;
}

.pr-xl {
    padding-right: @8;
}

.pr-xxl {
    padding-right: @13;
}

.pr-xxxl {
    padding-right: @1*21;
}

.pr-xxxxl {
    padding-right: @1*34;
}

.pb-0 {
    padding-bottom: 0;
}

.pb-1 {
    padding-bottom: @1;
}

.pb-1-5 {
    padding-bottom: @1-5;
}

.pb-2 {
    padding-bottom: @2;
}

.pb-3 {
    padding-bottom: @3;
}

.pb-4 {
    padding-bottom: @4;
}

.pb-5 {
    padding-bottom: @5;
}

.pb-6 {
    padding-bottom: @6;
}

.pb-7 {
    padding-bottom: @7;
}

.pb-8 {
    padding-bottom: @8;
}

.pb-9 {
    padding-bottom: @9;
}

.pb-10 {
    padding-bottom: @10;
}

.pb-11 {
    padding-bottom: @11;
}

.pb-12 {
    padding-bottom: @12;
}

.pb-13 {
    padding-bottom: @13;
}

.pb-14 {
    padding-bottom: @14;
}

// .pb-xl { padding-bottom: @padding-xl; }
// .pb-xxl { padding-bottom: @padding-xxl; }
.pb-xs {
    padding-bottom: @1;
}

.pb-sm {
    padding-bottom: @2;
}

.pb-md {
    padding-bottom: @3;
}

.pb-lg {
    padding-bottom: @5;
}

.pb-xl {
    padding-bottom: @8;
}

.pb-xxl {
    padding-bottom: @13;
}

.pb-xxxl {
    padding-bottom: @1*21;
}

.pb-xxxxl {
    padding-bottom: @1*34;
}

.pb-large {
    padding-bottom: @1*60;
}

.pb-huge {
    padding-bottom: @1*80;
}

.pl-0 {
    padding-left: 0;
}

.pl-half {
    padding-right: @half
}

;

.pl-1 {
    padding-left: @1;
}

.pl-1-5 {
    padding-left: @1-5;
}


.pl-2 {
    padding-left: @2;
}

.pl-3 {
    padding-left: @3;
}

.pl-4 {
    padding-left: @4;
}

.pl-5 {
    padding-left: @5;
}

.pl-6 {
    padding-left: @6;
}

.pl-7 {
    padding-left: @7;
}

.pl-8 {
    padding-left: @8;
}

.pl-9 {
    padding-left: @9;
}

.pl-10 {
    padding-left: @10;
}

.pl-11 {
    padding-left: @11;
}

.pl-12 {
    padding-left: @12;
}

// .pl-xl { padding-left: @padding-xl; }
// .pl-xxl { padding-left: @padding-xxl; }

.pl-xs {
    padding-left: @1;
}

.pl-sm {
    padding-left: @2;
}

.pl-md {
    padding-left: @3;
}

.pl-lg {
    padding-left: @5;
}

.pl-xl {
    padding-left: @8;
}

.pl-xxl {
    padding-left: @13;
}

.pl-xxxl {
    padding-left: @1*21;
}

.pl-xxxxl {
    padding-left: @1*34;
}

.up-1 {
    position: relative;
    bottom: 1px;
}

.up-2 {
    position: relative;
    bottom: 2px;
}

.up-3 {
    position: relative;
    bottom: 3px;
}

.up-4 {
    position: relative;
    bottom: 4px;
}

.up-5 {
    position: relative;
    bottom: 5px;
}

.up-6 {
    position: relative;
    bottom: 6px;
}

.down-1 {
    position: relative;
    top: 1px;
}

.down-2 {
    position: relative;
    top: 2px;
}

.down-3 {
    position: relative;
    top: 3px;
}

.down-4 {
    position: relative;
    top: 4px;
}

.down-5 {
    position: relative;
    top: 5px;
}

.down-6 {
    position: relative;
    top: 6px;
}

.letters-close {
    letter-spacing: -0.7px;
}

.letters-farther {
    letter-spacing: -.28px;
}

.p-content {
    .p-4;

    @media @sm-up {
        .p-8;
    }

    @media @md-up {
        padding: @8 64px;
    }
}

.px-content {
    .px-5;

    @media @md-up {
        .px-10;
    }

    @media @lg-up {
        padding-left: 60px;
        padding-right: 60px;
    }
}

.px-content-lg {
    .px-0;

    @media @sm-up {
        .px-4;
    }

    @media @md-up {
        padding-left: @12;
        padding-right: @12;
    }
}

@media @sm-up {
    .pl-xl-lg {
        padding-left: @padding-xl;
    }

    .pr-xl-lg {
        padding-right: @padding-xl;
    }
}

@media @sm-down {
    .pl-xl-sm-down {
        padding-left: @padding-xl;
    }

    .pr-xl-sm-down {
        padding-right: @padding-xl;
    }
}

@media @md-up {
    .m-0-md-up {
        .m-0;
    }

    .p-0-md-up {
        .p-0;
    }

    .pr-5-md-up {
        .pr-5;
    }

    .pb-5-md-up {
        .pb-5;
    }
}

.px-8-lg-down {
    .px-0;

    @media @lg-down {
        .px-8;
    }
}

.mx-8-lg-down {
    .mx-0;

    @media @lg-down {
        .mx-8;
    }
}

.mb-10-sm-down {
    .mb-0;

    @media @sm-down {
        .mb-10;
    }
}

.button-spacer {
    padding-bottom: 100px;
}


.display-sm {
    @media @sm-up {
        display: none;
    }
}

.hide-xs {
    @media @xs-down {
        display: none;
    }
}

.flex-no-wrap {
    flex-wrap: nowrap !important;
}