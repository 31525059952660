@import '../fonts/font-colors.less';

// Breakpoints
// -----------------------------
@breakpoint-xs: 320px;
@breakpoint-sm: 576px;
@breakpoint-md: 768px;
@breakpoint-lg: 992px; // Used for printing
@breakpoint-xl: 1200px;

// Container limits
// -----------------------------
@container-max-width-sm: 540px;
@container-max-width-md: 720px;
@container-max-width-lg: 960px;
@container-max-width-xl: 1200px;

// You won't need to edit these, unless you really want to rename them.
// -----------------------------
@breakpoint-xs-max: (@breakpoint-sm - 1px);
@breakpoint-sm-max: (@breakpoint-md - 1px);
@breakpoint-md-max: (@breakpoint-lg - 1px);
@breakpoint-lg-max: (@breakpoint-xl - 1px);
@xs-up: ~'(min-width: @{breakpoint-xs})';
@sm-up: ~'(min-width: @{breakpoint-sm})';
@md-up: ~'(min-width: @{breakpoint-md})';
@lg-up: ~'(min-width: @{breakpoint-lg})';
@xl-up: ~'(min-width: @{breakpoint-xl})';
@xs-down: ~'(max-width: @{breakpoint-xs-max})';
@sm-down: ~'(max-width: @{breakpoint-sm-max})';
@md-down: ~'(max-width: @{breakpoint-md-max})';
@lg-down: ~'(max-width: @{breakpoint-lg-max})';
@xs: @xs-down;
@sm: @sm-up and @sm-down;
@md: @md-up and @md-down;
@lg: @lg-up and @lg-down;
@xl: @xl-up;

@bolt-z-index-sticky: 10;

@media all and (min-width: 576px) {
  .bolt-typography .bolt-footer .bolt-row .bolt-footer-logo,
  .bolt-typography .bolt-footer .bolt-row .bolt-footer-logo-basic,
  .bolt-typography .bolt-footer .bolt-row .bolt-footer-social {
    flex: 0 0 auto;
    width: auto;
  }
}

@media all and (min-width: 576px) {
  .bolt-typography .bolt-footer-social {
    text-align: right;
  }
}

.sticky-container {
  z-index: @bolt-z-index-sticky;
  position: sticky;
  bottom: 0;
  width: 100%;
  font-size: 16px;

  .bolt-btn-primary {
    color: @dark-blue;

    button {
      background-color: #e8e9ea;
      font-size: 18px;
      border-radius: 4px;
      border-color: #e8e9ea;
    }
  }

  .sticky-button-container {
    background-color: @vibrant-blue;
    color: @white;
    justify-content: center;
    min-height: 60px;
    display: flex;
    align-items: center;
    font-size: 16px;
  }
}
