.bolt-typography .bolt-footer .bolt-row .bolt-footer-logo,
.bolt-typography .bolt-footer .bolt-row .bolt-footer-logo-basic {
    margin-bottom: 32px;
}

.bolt-typography .bolt-footer .bolt-row {
    justify-content: space-between;
}

.bolt-typography .bolt-footer-links {
    margin-bottom: 20px;
}