// Brand colors
// -----------------------------

@black: #000000;

// Mock up colors
@main-blue: #2050c7;
@main-blue-hover: #204fc75e;
@section-background: #f7f8fc;
@admin-edit-background: #bde3ff;
@page-divider: #d0d3d4;
@admin-header-light-blue: #0d99ff;

// Primary
@vibrant-blue: #0047bb;
@white: #ffffff;

// Secondary
@N-crop-blue: #002da2;
@darker-crop-blue: #0f2596;
@medium-blue: #326295;
@nw-medium-blue: #1f74db;
@sky-blue: #598be3;
@dark-blue: #141b4d;
@indigo-wild: #0a1138;
@light-blue: #8cc8e9;
@pale-blue: #749fea;
@background-blue: #9bbefd;
@image-fill-blue: #181f56;

// Accents

@mint: #6eceb2;
@dark-mint: #008574;
@light-mint: #b6e6d8;
@turq-breeze: #dbfff6;
@sea-blue: #72b0bd;
@dark-sea-blue: #005670;
@lime: #d6ed92;
@light-lime: #f6ffdd;
@checkbox-green: #009c90;
@cucumber-palm: #2f9990;
@lavender: #d7a9e3;
@deep-purple: #890c58;
@deep-purple-translucent: rgba(137, 12, 88, 0.7);
@medium-purple: #842062;
@sunburst-orange: #ff9800;
@cherry-red: #cb333b;
@lemon-yellow: #f9e27d;
@mustard: #a38b00;

//Notifications
@error: #eb0004;
@info: #007d8a;
@success: #58c140;
@warning: #e85b16;

// Neutrals
@charcoal-light: #3c3c3e;
@charcoal: #171717;
@dusky-grey: #f0f4f7;
@pale-grey: #d0d3d4;
@pale-grey-10: #fbfbfb;
@pale-grey-25: #f3f3f3;
@pale-grey-50: #e8e9ea;
@pale-grey-75: #dcdfdf;
@dark-grey: #707070;
@fossil: #afa9a0;
@dark-fossil: #7a7266;

// Supports
@faded-charcoal: fade(@charcoal, 80%);

// Ant colors (used throughout the default build)
@disclaimer: @charcoal;
@whisper: @pale-grey-25;
@blue: @vibrant-blue;
@dark-red: @cherry-red;
@perano-blue: @light-blue;
@mist-blue: @light-blue;
@faded-charcoal-5: fade(@charcoal, 5%);
@faded-charcoal-15: fade(@charcoal, 15%);
@faded-charcoal-25: fade(@charcoal, 25%);
@faded-charcoal-50: fade(@charcoal, 50%);
@faded-charcoal-80: fade(@charcoal, 80%);

// TODO: Phase Out

// Sub-navbar uses this:
@red: @cherry-red;

// Modal bg uses this:
@light-grey: @pale-grey-25;

@fadedGrey: fade(@dark-grey, 70%);

// /*
// Color shades can be improvised but this give a base common language.
//  (eg. “green-100” through “green-900”, vs “light-green” and “dark-green” vs “charcoal”, “green”, etc.)
// */

// // @charcoal-100: mix(@charcoal, white, 10);
// // @charcoal-200: mix(@charcoal, white, 20);
// // @charcoal-300: mix(@charcoal, white, 40);
// // @charcoal-400: mix(@charcoal, white, 60);
// // @charcoal-500: @charcoal;
// // @charcoal-600: mix(black, @charcoal, 20);
// // @charcoal-700: mix(black, @charcoal, 40);
// // @charcoal-800: mix(black, @charcoal, 60);
// // @charcoal-900: mix(black, @charcoal, 80);

.font-color-nw-dark-blue {
  color: @dark-blue;
}

.font-color-vibrant-blue {
  color: @vibrant-blue;
}

.font-color-nw-medium-blue {
  color: @nw-medium-blue;
}

.font-color-light-blue {
  color: @light-blue;
}

.font-color-cherry-red {
  color: @cherry-red;
}

.font-color-nw-pale-grey {
  color: @pale-grey;
}

.font-color-sunburst-orange {
  color: @sunburst-orange;
}

.font-color-darker-crop-blue {
  color: @darker-crop-blue;
}

.color-main-blue {
  color: @main-blue;
}

.color-main-blue-hover {
  color: @main-blue-hover;
}

.color-section-background {
  color: @section-background;
}
