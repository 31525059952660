@import '../fonts/font-colors.less';

@states: '&:hover,&:focus,&:active,&:visited,&:enabled';

@font-face {
    font-family: tiempos;
    src: url('../fonts/tiempos/tiempos-headline-web-semibold.woff2') format('woff2');
    src: url('../fonts/tiempos/tiempos-headline-web-semibold.woff') format('woff');
    src: url('../fonts/tiempos/tiempos-fine-web-semibold.woff2') format('woff2');
    src: url('../fonts/tiempos/tiempos-fine-web-semibold.woff') format('woff');
}

.tiempos {
    font-family: 'tiempos', Georgia, 'Times New Roman', Times, serif !important;
}

.flex {
    display:flex;
    &.hidden {
        display:none;
    }
}

.flexCenter {
    .flex;
    justify-content: center;
    align-items: center;
}

.fontDetails(@size, @weight: initial, @lineHeight: 1.4) {
    font-size: (@size)px !important;
    font-weight: @weight !important;
    line-height: (@size * @lineHeight)px !important;
}

.bolt-primary {
    color: @dark-blue !important;
    background-color: #e8e9ea;
    border: 3px solid #e8e9ea;
}

.bolt-secondary {
    background: none;
    background-color: transparent;
    border: 3px solid @nw-medium-blue;
    color: @dark-blue;
}

.bolt-outline {
    &:focus, &:active {
        box-shadow: 0 0 0 1px #fff,0 0 2px 4px #5BC6E8!important;
        outline: none!important;
    }
}

// tiempos headings (must use for 1st heading and numbers)
// width over 1200 px
.headingBiggestTiempos { 
    .fontDetails(84, bold, 1);
    .tiempos;
}
// width under 1200px
.headingBiggerTiempos { 
    .fontDetails(62, bold, 1);
    .tiempos; 
}
// width under 1066px
.headingBigTiempos { 
    .fontDetails(48, bold, 1);
    .tiempos; 
}
// width under 576px
.headingTiempos { 
    .fontDetails(36, bold, 1);
    .tiempos; 
}
// width under 376px
.headingSmallTiempos { 
    .fontDetails(28, bold, 1);
    .tiempos; 
}

// tiempos non-headings in case needed
.largeTiempos {
    .fontDetails(48);
    .tiempos;
}
.medLargeTiempos {
    .fontDetails(39);
    .tiempos;
}
.mediumTiempos {
    .fontDetails(32);
    .tiempos;
}
.smallMediumTiempos {
    .fontDetails(28);
    .tiempos;
}
.smallTiempos {
    .fontDetails(24);
    .tiempos;
}
.leadTiempos {
    .fontDetails(22);
    .tiempos;
}
.legalTiempos { 
    .fontDetails(14);
    .tiempos; 
    @media all and (max-width:350px) {
        .fontDetails(12);
    }
}
// unless specified by design all body elements 18pt regardless of viewport size
.bodyTiempos { 
    .fontDetails(18);
    .tiempos;
}


// non tiempos headings (for all sub headings and emphasis points on page) really usually no bigger than 36 unless special case
.heading { .fontDetails(36, bold, 1.3) }
.headingBig { .fontDetails(48, bold, 1.3) }
.headingBigger { .fontDetails(62, bold, 1.3) }

.headingContent { .fontDetails(18, bold, 1.3) }

.large { .fontDetails(48) }
.medium { .fontDetails(32) }
.small { .fontDetails(24) }
.lead { .fontDetails(22) }
.legal { 
    .fontDetails(14); 
    @media all and (max-width:350px) {
        .fontDetails(12);
    }
}
// unless specified by design all body elements 18pt regardless of viewport size
.body { .fontDetails(18); }

.Main {
    // never use true black or true white for text it is too harsh on the eyes
    color: #111111 !important;
    margin: 0;
}

.global {
    body {
        .Main;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        .hidden {
            display:none!important
        }
        .invisible {
            opacity: 0;
        }
    }
}