@import "./fonts/font-colors.less";

// Override less variables to here
// View all variables: https://github.com/NG-ZORRO/ng-zorro-antd/blob/master/components/style/themes/default.less;
@error-color: @red;
@body-background: @white;

// Base Font Family and Color
// ---
@font-family: Gotham;
@heading-family: gotham, sans-serif;
//@text-color: @charcoal;   // base text of the site
@text-color: @charcoal-light; // base text of the site

// FONTS Sizes
// ---
.bolt-typography body {
    font-size: 16px;
}

@font-size-base: 16px;
@font-size-huge: 64px;
@font-size-xxxxxl: @font-size-base + 20px; // 36px
@font-size-xxxxl: @font-size-base + 18px; // 34px
@font-size-xxxl: @font-size-base + 16px; // 32px
@font-size-xxl: @font-size-base + 10px; // 26px
@font-size-xl: @font-size-base + 8px; // 24px
@font-size-lg: @font-size-base + 4px; // 20px
@font-size-md: @font-size-base + 2px; // 18px
@font-size-normal: @font-size-base; // 16px
@font-size-sm: @font-size-base - 2px; // 14px
@font-size-xs: @font-size-base - 4px; // 12px
@font-weight-xlight: 200;
@font-weight-light: 300;
@font-weight-regular: 400;
@font-weight-medium: 500;
@font-weight-semibold: 600;
@font-weight-bold: 700;
@font-weight-heavy: 900;

// Line Heights
// ---
@line-height-tightest: 1.0571;
@line-height-tighter: 1.14;
@line-height-tight: 1.1875;
@line-height-loose: 1.2727;
@line-height-looser: 1.3333;
@line-height-base: 1.1875; // Used for base text in the appl
@line-height-md: 1.556;
@line-height-loosest: 1.75;

// Icon Size
// ---
@icon-base: 40px;

// Vertical Margins
// ---
@margin-xxl: 48px;
@margin-xl: 36px;
@margin-lg: 24px; // containers

// Height rules
// ---
@height-base: 40px;

// Border Radius
// ---
@border-radius-base: 6px;
@border-radius-sm: 4px;

// Border color
// ---
@border-color-base: @charcoal; // base border outline a component
@border-width-base: 1px; // width of the border for a component
@border-style-base: solid; // style of a components border

// Shadow
// ---
@shadow-color: fade(black, 10%);
@shadow-color-inverse: fade(black, 10%);
@box-shadow-base: @shadow-1;
@shadow-1: 0 1px 6px 0px @shadow-color, 0 1px 1px 0px @shadow-color; //0 1px 4px 0 @shadow-color;
@shadow-2: 1px 5px 11px 1px @shadow-color; //1px 7px 11px 0 @shadow-color;

// Layout
// ---
@layout-footer-padding: 10px 20px;

// ---
// 4) Buttons
// ---
@btn-border-radius-lg: @border-radius-base;
@btn-height-base: 50px;
@btn-primary-color: #E8EEF3;
@btn-primary-bg: #0047BB;

@btn-disable-color: #00487C;
@btn-disable-bg: #E8EEF3;
@btn-disable-border: #00487C;


// Form
// ---
@label-required-color: @charcoal;
@label-color: @charcoal;
@form-item-margin-bottom: @margin-xl;
@form-item-label-font-size: @font-size-base;
@form-item-label-height: 25px;

// Input
// ---
@input-height-base: @height-base;
@input-placeholder-color: @dark-grey;
@input-color: @charcoal;

// Checkbox
// ---
@checkbox-size: 18px;
@checkbox-border-radius: 2px;

// Radio
// ---
@radio-size: 16px;

// Select
// ---
@select-border-color: @border-color-base;
@select-dropdown-bg: @pale-grey-25;
@select-item-selected-bg: @dark-grey;
@select-item-active-bg: @pale-grey;
@select-background: @pale-grey-25;
@select-clear-background: @pale-grey-25;
@select-multiple-item-height-lg: 32px;


// Switch
// ---
@switch-height: 30px;
@switch-sm-height: 25px;
@switch-min-width: 20px;
@switch-sm-min-width: 18px;

// Card
// ---
@card-background: @white;

// Carousel
// ---
@carousel-dot-width: 8px;
@carousel-dot-height: 8px;
@carousel-dot-active-width: 30px;

// Collapse
// ---
@collapse-header-bg: white; //@background-color-light;

//CollapsePanel
// ---
@collapse-panel-border-radius: 4px; //@border-radius-base;



// Descriptions
// ---
@descriptions-bg: @pale-grey-50;

//Dropdown
// ---
@dropdown-selected-color: @vibrant-blue;

// Modal
// --
@modal-header-bg: @light-grey;
@modal-content-bg: @white;
@modal-footer-bg: @light-grey;

// Popover
// ---
@popover-bg: @pale-grey-50;
@popover-color: @charcoal;

// Rate
// ---
@rate-star-color: @vibrant-blue;

// Table
// --
@table-border-radius-base: 0px;